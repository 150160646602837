import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import chefIconImageSrc from "images/Icon_card.svg";
import celebrationIconImageSrc from "images/Icon_love.svg";
import shopIconImageSrc from "images/Icon_location.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Adoçados com frutas <HighlightedText>Veganos.</HighlightedText></>}
        description="Doces visando evitar açucar refinado, e utilizando ingredientes que não possuem origem animal."
        imageSrc="https://images.unsplash.com/photo-1560621418-d0e61805932c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=464&q=80&fp-x=.825&fp-y=.25&fp-z=2"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Peça Já"
        primaryButtonUrl="#contato"
        watchVideoButtonText="Conheça sobre Veganismo"
        watchVideoYoutubeUrl="https://www.youtube.com/embed/3LnPqVlhLp4"
      />
      <MainFeature
        subheading={<Subheading>Começamos em 2022</Subheading>}
        heading={
          <>
            Em São José dos Campos temos
            <wbr /> <HighlightedText>Delivery e Retirada</HighlightedText>
          </>
        }
        description={
          <Description>
            Começamos com um pequeno sonho de aplicar o que aprendemos e acreditamos. Esperamos continuar crescendo
              para alcançar ainda mais pessoas e estabelecimentos!
            <br />
            <br />
            Entregamos por conta própria, via Uber ou disponibilizamos retirada.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={
          "https://images.unsplash.com/photo-1619051805375-7b83440bb11b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=364&q=80&fp-x=.825&fp-y=.25&fp-z=2"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
        <section id="opcoes"/>
        <TabGrid
            heading={
                <>
                    Confira nossas <HighlightedText>opções</HighlightedText>:
                </>
            }
        />
        <section id="contato" />
        <Features
            heading={
                <>
                    Serviços <HighlightedText>pra você</HighlightedText>
                </>
            }
            cards={[
                {
                    imageSrc: shopIconImageSrc,
                    title: "Em SJC",
                    description: "Entrega e Retirada",
                    url: "javascript:void(0)"
                },
                {
                    imageSrc: chefIconImageSrc,
                    title: "Pagamento Flexível",
                    description: "Aceitamos Pix, débito e crédito!",
                    url: "javascript:void(0)"
                },
                {
                    imageSrc: celebrationIconImageSrc,
                    title: "Veganos mesmo",
                    description: "Castanhas, Tâmaras e óleo de coco. Mas nada animal!",
                    url: "javascript:void(0)"
                }
            ]}

            imageContainerCss={tw`p-2!`}
            imageCss={tw`w-20! h-20!`}
        />
      {/*<MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      />*/}
      <Footer />
    </AnimationRevealPage>
  );
}
