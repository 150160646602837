import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import {useParams} from "react-router-dom";

import chocolate from "images/tortas/chocolate.jpeg";
import pacoca from "images/tortas/pacoca.jpeg";
import limao from "images/tortas/limao.jpeg";
import morango from "images/tortas/morango.jpeg";
import cupuacu from "images/tortas/cupuacu.jpeg";
import maracuja from "images/tortas/maracuja.jpeg";
// import churros from "images/tortas/maracuja.jpeg";
import prestigio from "images/tortas/prestigio.png";
import cookie from "images/tortas/cookie.jpeg";
import styled from "styled-components";
import tw from "twin.macro";

const Table = styled.div`
  ${tw`flex flex-row lg:my-12 py-12`}
`;

const Placeholder = styled.div`
  ${tw`basis-1/4`}
`;

const InnerTable = styled.table`
  border-width:1px;
  width: 200%;
  ${tw`basis-1/2 table-auto`}
`

export default () => {
    const { variation } = useParams()
    const details = getDetails(variation)

    return (
    <AnimationRevealPage>
      <Hero
          imageSrc={details.img}
          heading={details.name}
          description={details.description}
      />
      {/*<Features />*/}
      <Pricing
          prices={details.prices}
          flavor={details.name}
      />
        <section id="nutri"></section>
        <Table>
            <Placeholder></Placeholder>
            <InnerTable>
                <tr>
                    <td colspan="3">INFORMAÇÃO NUTRICIONAL<br/>Porção de 60g</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Quantidade por Porção</td>
                    <td>%VD (*)</td>
                </tr>
                <tr>
                    <td>Valor Energético</td>
                    <td>{details.nutrients.calories[0]} Kcal = {details.nutrients.calories[1]} KJ</td>
                    <td>{details.nutrients.calories[2]} %</td>
                </tr>
                <tr>
                    <td>Carboidratos</td>
                    <td>{details.nutrients.carb[0]} g</td>
                    <td>{details.nutrients.carb[1]} %</td>
                </tr>
                <tr>
                    <td>Proteínas</td>
                    <td>{details.nutrients.protein[0]} g</td>
                    <td>{details.nutrients.protein[1]} %</td>
                </tr>
                <tr>
                    <td>Gorduras Totais<br/>Gorduras Saturadas</td>
                    <td>{details.nutrients.fat[0]} g<br/>{details.nutrients.satFat[0]} g</td>
                    <td>{details.nutrients.fat[1]} %<br/>{details.nutrients.satFat[1]} %</td>
                </tr>
                <tr>
                    <td>Fibra Alimentar</td>
                    <td>{details.nutrients.fiber[0]} g</td>
                    <td>{details.nutrients.fiber[1]} %</td>
                </tr>
                { details.nutrients.iron &&
                    <tr>
                        <td>Ferro</td>
                        <td>{details.nutrients.iron[0]} mg</td>
                        <td>{details.nutrients.iron[1]} %</td>
                    </tr>
                }
                { details.nutrients.salt &&
                    <tr>
                        <td>Sódio</td>
                        <td>{details.nutrients.salt[0]} mg</td>
                        <td>{details.nutrients.salt[1]} %</td>
                    </tr>
                }
                <tr>
                    <td colspan="3">Não contém quantidades significativas de gorduras trans{!details.nutrients.salt && ' e sódio'}</td>.
                </tr>
                <tr>
                    <td colspan="3">(*) % Valore Diários com base em uma dieta de 2.000 kcal ou 8.400 KJ. Seus<br/>
                    valores diários podem ser maiores ou menores dependendo de suas necessidades<br/>
                    energéticas.</td>
                </tr>
            </InnerTable>
            <Placeholder></Placeholder>
        </Table>
        <FAQ />
        {/*<MainFeature
        subheading={variation}
        heading="Highly Redundant Servers With Backup"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      />
      <MainFeature
        subheading="Secure"
        heading="State of the Art Computer Security"
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Testimonial />
      <Footer />*/}
    </AnimationRevealPage>
  );
}

function getDetails(variation = 'chocolate') {
    return {
        chocolate: {
            name: 'Chocolate',
            prices: ['12', '29', '51', '91'],
            img: chocolate,
            description: 'Torta feita com cacau, adoçado com tâmaras, com massa de castanha' +
                'de caju e uva passa escura.',
            nutrients: {
                calories: ['160', '670', '8'],
                carb: ['16', '5'],
                protein: ['3.0', '4'],
                fat: ['9.4', '17'],
                satFat: ['4.2', '19'],
                fiber: ['2.1', '8'],
                iron: ['1.07', '8'],
            }
        },
        pacoca: {
            name: 'Paçoca',
            prices: ['12', '28', '49', '88'],
            img: pacoca,
            description: 'Torta feita com amendoin, adoçado com tâmaras, com massa de castanha' +
                'de caju e tâmaras.',
            nutrients: {
                calories: ['183', '769', '9'],
                carb: ['16', '5'],
                protein: ['4.5', '6'],
                fat: ['11', '20'],
                satFat: ['3.4', '16'],
                fiber: ['2.4', '10'],
                salt: ['38', '2']
            }
        },
        limao: {
            name: 'Limão com Chocolate',
            prices: ['14', '32', '57', '104'],
            img: limao,
            description: 'Torta feita com limão, uvas passas claras, com massa de castanha' +
                'de caju, cacau e uva passa escura.',
            nutrients: {
                calories: ['142', '595', '7'],
                carb: ['15', '5'],
                protein: ['2.2', '3'],
                fat: ['8.2', '15'],
                satFat: ['4.5', '21'],
                fiber: ['1.5', '6'],
                iron: ['0.74', '5']
            }
        },
        morango: {
            name: 'Morango com Coco',
            prices: ['14', '33', '60', '109'],
            img: morango,
            description: 'Torta feita com morangos, coco ralado, beterraba, com massa de castanha' +
                'de caju e uvas passas claras.',
            nutrients: {
                calories: ['149', '626', '7'],
                carb: ['14', '5'],
                protein: ['2.5', '3'],
                fat: ['9.0', '16'],
                satFat: ['4.7', '21'],
                fiber: ['1.6', '7'],
                iron: ['0.78', '6']
            }
        },
        cupuacu: {
            name: 'Cupuaçu com Chocolate',
            prices: ['14', '35', '67', '122'],
            img: cupuacu,
            description: 'Torta feita com cupuaçu, uvas passas claras, com massa de castanha' +
                'de caju e tâmaras.',
            nutrients: {
                calories: ['152', '640', '8'],
                carb: ['12', '6'],
                protein: ['2.7', '4'],
                fat: ['8.1', '15'],
                satFat: ['3.4', '16'],
                fiber: ['1.7', '7'],
                iron: ['0.85', '6']
            }
        },
        maracuja: {
            name: 'Maracujá',
            prices: ['14', '33', '58', '106'],
            img: maracuja,
            description: 'Torta feita com maracujá, uvas passas claras, com massa de castanha' +
                'de caju e uvas passas claras.',
            nutrients: {
                calories: ['158', '665', '8'],
                carb: ['16', '5'],
                protein: ['2.6', '3'],
                fat: ['9.4', '17'],
                satFat: ['4.8', '22'],
                fiber: ['1.2', '5'],
                iron: ['0.83', '6']
            }
        },
        churros: {
            name: 'Churros',
            prices: ['14', '32', '57', '104'],
            img: chocolate,
            description: 'Torta feita com canela, tâmaras, com massa de castanha' +
                'de caju e tâmaras.',
            nutrients: {
                calories: ['166', '699', '8'],
                carb: ['16', '6'],
                protein: ['2.8', '4'],
                fat: ['9.9', '18'],
                satFat: ['4.5', '21'],
                fiber: ['1.7', '7'],
                iron: ['0.87', '6']
            }
        },
        prestigio: {
            name: 'Prestígio',
            prices: ['13', '30', '54', '97'],
            img: prestigio,
            description: 'Torta feita com coco ralado, tâmaras, com massa de castanha' +
                'de caju e uvas passas escuras.',
            nutrients: {
                calories: ['153', '642', '8'],
                carb: ['15', '5'],
                protein: ['2.5', '3'],
                fat: ['9.2', '17'],
                satFat: ['5.0', '23'],
                fiber: ['1.8', '7'],
                iron: ['0.78', '6']
            }
        },
        cookie: {
            name: 'Cookie',
            prices: ['17', '47', '90', '170'],
            img: cookie,
            description: 'Torta com massa assada de farinha de aveia, castanha de caju, farinha de linhaça e chocolate vegano, e ainda recheada com creme de tâmaras e cacau',
            nutrients: {
                calories: ['144', '604', '7'],
                carb: ['19', '7'],
                protein: ['0.9', '1'],
                fat: ['6.8', '12'],
                satFat: ['5.2', '23'],
                fiber: ['2.2', '9']
            }
        },
    }[variation]
}
